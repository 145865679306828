.badge {display: inline-flex;width: 5rem;min-width: min-content;align-items: center;border-radius: 0.25rem;padding-left: 0.5rem;padding-right: 0.5rem;padding-top: 0.125rem;padding-bottom: 0.125rem;vertical-align: middle;font-size: 0.75rem;line-height: 1rem;font-weight: 500
}.badge.blue {--tw-bg-opacity: 1;background-color: rgba(219, 234, 254, var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgba(30, 64, 175, var(--tw-text-opacity))
}.badge.blue svg {--tw-text-opacity: 1;color: rgba(96, 165, 250, var(--tw-text-opacity))
}.badge.green {--tw-bg-opacity: 1;background-color: rgba(209, 250, 229, var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgba(6, 95, 70, var(--tw-text-opacity))
}.badge.green>svg {--tw-text-opacity: 1;color: rgba(52, 211, 153, var(--tw-text-opacity))
}.badge.yellow {--tw-bg-opacity: 1;background-color: rgba(255, 250, 228, var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgba(146, 64, 14, var(--tw-text-opacity))
}.badge.yellow>svg {--tw-text-opacity: 1;color: rgba(251, 191, 36, var(--tw-text-opacity))
}.badge.orange {--tw-bg-opacity: 1;background-color: rgba(255, 237, 220, var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgba(186, 101, 50, var(--tw-text-opacity))
}.badge.orange>svg {--tw-text-opacity: 1;color: rgba(186, 101, 50, var(--tw-text-opacity))
}.badge.red {--tw-bg-opacity: 1;background-color: rgba(254, 226, 226, var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgba(153, 27, 27, var(--tw-text-opacity))
}.badge.red>svg {--tw-text-opacity: 1;color: rgba(248, 113, 113, var(--tw-text-opacity))
}.badge.gray {--tw-bg-opacity: 1;background-color: rgba(244, 244, 245, var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgba(39, 39, 42, var(--tw-text-opacity))
}.badge.gray>svg {--tw-text-opacity: 1;color: rgba(161, 161, 170, var(--tw-text-opacity))
}.badge.black {border-width: 1px;--tw-border-opacity: 1;border-color: rgba(107, 114, 128, var(--tw-border-opacity));--tw-text-opacity: 1;color: rgba(107, 114, 128, var(--tw-text-opacity))
}.badge.darkRed {--tw-bg-opacity: 1;background-color: rgba(205, 37, 37, var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgba(255, 255, 255, var(--tw-text-opacity))
}.badge.darkRed>svg {--tw-text-opacity: 1;color: rgba(255, 255, 255, var(--tw-text-opacity))
}.badge.bordered {border-width: 1px;--tw-border-opacity: 1;border-color: rgba(248, 87, 55, var(--tw-border-opacity));--tw-bg-opacity: 1;background-color: rgba(255, 255, 255, var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgba(248, 87, 55, var(--tw-text-opacity))
}.badge.white {border-width: 1px;--tw-border-opacity: 1;border-color: rgba(107, 114, 128, var(--tw-border-opacity));--tw-bg-opacity: 1;background-color: rgba(255, 255, 255, var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgba(107, 114, 128, var(--tw-text-opacity))
}.badge.suppliOrange {--tw-bg-opacity: 1;background-color: rgba(248, 87, 55, var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgba(255, 255, 255, var(--tw-text-opacity))
}